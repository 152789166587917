<template>
  <div class="text-icon-tiles" :class="themeColorClass">
    <UiHeader
      v-if="header"
      class="text-icon-tiles__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <hr v-if="header && itemList.length" />

    <div v-if="itemList.length" class="text-icon-tiles__wrapper">
      <component
        :is="haveLink(item) && !haveButton(item) ? 't3-link' : 'div'"
        v-for="item in itemList"
        :key="item.id"
        :to="item.link"
        class="text-icon-tiles__item"
        :class="[
          { hover: haveLink(item) && !haveButton(item) },
          variantColorClass(item)
        ]"
      >
        <div v-if="item.logo" class="text-icon-tiles__icon-wrap">
          <UiImg :image="item.logo" :width="48" decorative />
        </div>

        <T3HtmlParser
          v-if="item.name"
          class="text-icon-tiles__title"
          :content="item.name"
        />

        <T3HtmlParser
          v-if="item.text"
          :content="item.text"
          class="text-icon-tiles__desc"
        />

        <ButtonBase
          v-if="haveLink(item) && haveButton(item)"
          class="text-icon-tiles__btn"
          :button="{
            content: item.label,
            variant: 'outline-white',
            link: item.link
          }"
        />
      </component>
    </div>

    <hr v-if="header && itemList.length" />
  </div>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesItemProps, UiCeProductBoxesProps } from '~/types'
import { ButtonBase, UiHeader, UiImg } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import { isLinkValid } from '~ui/helpers/linkUtils'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)

const haveLink = (item: UiCeProductBoxesItemProps) => isLinkValid(item.link)
const haveButton = (item: UiCeProductBoxesItemProps) => !!item.label

const variantColorClass = (item: UiCeProductBoxesItemProps) =>
  useTheming(item.variant, 'color')
</script>

<style lang="scss">
.text-icon-tiles {
  &__heading {
    & > * {
      margin-bottom: rem(20px);
      font-weight: 600;
    }
  }

  & hr {
    height: 1px;
    background-color: color(wild-sand);
    border: none;
  }

  & hr + &__wrapper {
    margin-block: rem(48px);
  }

  &__wrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(rem(300px), 1fr));
    gap: spacing(sm) spacing(md);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: rem(24px);
    border-radius: em(4px);
    box-shadow:
      0 4px 26px 0 hsl(0 0% 0% / 2.5%),
      0 12px 22px hsl(0 0% 0% / 3%);

    &.hover {
      transition: box-shadow 0.25s;
      box-shadow: none;

      &:is(:hover, :focus) {
        box-shadow:
          0 4px 26px 0 hsl(0 0% 0% / 2.5%),
          0 12px 22px hsl(0 0% 0% / 3%);
      }
    }
  }

  &__icon-wrap {
    display: inline-block;
    border-radius: em(4px);
    padding: rem(12px);
    margin-bottom: rem(16px);
    background-color: var(--theme__theme-color);
    height: rem(64px);
    width: rem(64px);

    & img {
      height: 100%;
      width: 100%;
    }
  }

  &__item:not(.hover) &__icon-wrap img {
    filter: brightness(0) invert(1);
  }

  &__item.hover &__icon-wrap {
    background-color: color(white);
    box-shadow:
      0 2px 16px 0 hsl(0 0% 0% / 3.5%),
      0 8px 12px hsl(0 0% 0% / 3.5%);
    transition:
      box-shadow 0.25s,
      background-color 0.25s;
  }

  &__item.hover:is(:hover, :focus) &__icon-wrap {
    background-color: var(--theme__theme-color);
    box-shadow: none;

    & img {
      filter: brightness(0) invert(1);
    }
  }

  &__item.hover :is(&__title, &__desc p) {
    color: initial;
  }

  &__item[class*='color-'] &__icon-wrap {
    background-color: hsl(0 0% 100% / 8%);
  }

  &__title {
    font-size: rem(24px);
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0;
  }

  &__desc p {
    margin-block: rem(16px) rem(20px);
    font-size: rem(14px);

    & a {
      font-weight: 600;

      &:not(:first-child) {
        margin-top: rem(8px);
      }
    }
  }

  &__btn {
    width: 100%;
  }
}
</style>
